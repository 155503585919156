<template>
  <div class="home">
    <div id="content-container">
      <div class="grid-container">
        <div
          v-for="(i, idx) in Math.ceil(projects.data.length / 2)"
          :key="idx"
          class="grid-row"
          :class="`grid-row-${idx % 2}`"
        >
          <div v-for="(project, index) in projects.data.slice((i - 1) * 2, i * 2)" :key="index" class="grid-item">
            <jr-animation-tile :project="project" @finished="animationFinished(project)">
              <div class="item">
                <div class="img-wrapper" :class="getImageClass(project)">
                  <jr-lazy-image
                    v-if="project.fields.poster"
                    class="img-fluid"
                    :alt="project.fields.titleShort"
                    :lazy-src="project.fields.poster.fields.file.url"
                  >
                  </jr-lazy-image>
                  <span v-if="project.fields.titleShort" class="project__title">{{ project.fields.titleShort }}</span>
                </div>
              </div>
            </jr-animation-tile>
          </div>
        </div>
        <template v-if="projects.data.length === 0">
          <div v-for="(i, idx) in 5" :key="idx" class="grid-row" :class="`grid-row-${idx % 2}`">
            <div
              v-for="(project, index) in [1, 2, 3, 4, 5, 6].slice((i - 1) * 2, i * 2)"
              :key="index"
              class="grid-item"
            >
              <div class="item">
                <div
                  v-if="idx % 2 === 0"
                  class="img-wrapper"
                  :class="[index % 2 === 0 ? 'img-21-9' : 'img-16-9']"
                ></div>
                <div
                  v-if="idx % 2 !== 0"
                  class="img-wrapper"
                  :class="[index % 2 === 0 ? 'img-16-9' : 'img-21-9']"
                ></div>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
    <div id="animation-container" class="grid-container"></div>
  </div>
</template>

<script>
// compositions
import useProjects from '@/composition/useProducts';

// components
import { JrAnimationTile, JrLazyImage } from '@/components/';

export default {
  name: 'Home',

  components: {
    JrAnimationTile,
    JrLazyImage,
  },

  beforeRouteEnter(to, from, next) {
    next(vm => {
      const cloneContainer = document.getElementById('animation-container');
      if (cloneContainer) cloneContainer.innerHTML = '';
    });
  },

  setup() {
    const { projects } = useProjects();

    return {
      projects,
    };
  },

  methods: {
    getImageClass(project) {
      if (!project.fields) return;
      return project.fields.poster.fields.file.details.image.width /
        project.fields.poster.fields.file.details.image.height <
        2
        ? 'img-16-9'
        : 'img-21-9';
    },

    animationFinished(project) {
      this.$router.push({ name: 'Project', params: { id: project.sys.id } });
    },
  },
};
</script>

<style scoped lang="scss">
.home {
  min-height: 100vh;
}

.img-wrapper {
  position: relative;
  width: 100%;
  background: rgba(0, 0, 0, 0.1);
  min-height: 100%;

  &:before {
    background: black;
    content: '';
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    width: 100%;
    transition: opacity 0.4s;
    cursor: pointer;
    z-index: 100;
  }

  .no-touchevents &:hover {
    .project__title {
      cursor: pointer;
      opacity: 0.8;
      transform: translate(-50%, -50%) scale(1);
      transition: opacity 0.4s, transform 0.2s;
      user-select: none;
      z-index: 110;
    }

    &:before {
      opacity: 0.4;
      transition: opacity 0.4s;
    }
  }

  &.img-16-9 {
    padding-bottom: 56.25%;
  }

  &.img-21-9 {
    padding-bottom: 42.8571429%;
  }

  .img-fluid {
    cursor: pointer;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
  }
}

.project__title {
  color: white;
  left: 50%;
  opacity: 0;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%) scale(1.2);
  transition: opacity 0.4s, transform 0.2s;
}

#animation-container {
  align-content: center;
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 50%;
  max-width: 100%;
  opacity: 0;
  pointer-events: none;
  position: fixed;
  top: 0;
  transform: translateX(-50%);
  z-index: 1000;

  .img-wrapper {
    background: transparent;
  }

  .item {
    position: absolute;

    .img-fluid {
      height: auto;
    }
  }
}
</style>
