import { ref } from 'vue';
import { createContentfulClient } from '../contentful-client';

const client = createContentfulClient();

const byOrder = (a, b) => {
  if (a.fields.order < b.fields.order) return -1;
  if (a.fields.order > b.fields.order) return 1;
  return 0;
};

const projects = ref({
  status: 'NOT_REQUESTED',
  data: [],
});
const activeProject = ref({});

export default function useProduct() {
  async function getAllProjects() {
    const result = await client.getEntries();

    if (result) {
      projects.value = {
        status: 'RECEIVED',
        data: result.items.sort(byOrder),
      };
    }
  }

  function getProjectById(id) {
    if (projects.value.status !== 'RECEIVED') {
      getAllProjects().then(() => {
        activeProject.value = projects.value.data.find(project => project.sys.id === id);
      });
    } else {
      activeProject.value = projects.value.data.find(project => project.sys.id === id);
    }
  }

  return {
    activeProject,
    getAllProjects,
    getProjectById,
    projects,
  };
}
