<template>
  <footer class="footer">
    <div class="grid-container">
      <ul class="meta-list">
        <li class="meta-list__item">
          Jan Rödger<br />
          Weichselstraße 5<br />
          10247 Berlin<br />
          +4917664670771 <br />
          <a class="meta-list__item-link" href="mailto:mail@jan-roedger.de" rel="noopener" target="_blank"
            >mail@jan-roedger.de
          </a>
        </li>
      </ul>

      <div>
        <router-link class="nav__link" :to="{ name: 'Impressum' }"> Impressum </router-link>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'JrFooter',
};
</script>

<style lang="scss" scoped>
.footer {
  background: white; // rgba(0,0,0, 0.6);
  border-top: 1px solid rgba(0, 0, 0, 0.3);
  color: black;
  padding: 40px 0;
  width: 100%;

  .grid-container {
    display: flex;
    justify-content: space-between;
  }
}

.meta-list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.meta-list__item {
  margin: 0;
  padding: 0;

  &.margin-top {
    margin-top: 10px;
  }
}

.meta-list__item-link {
  color: black;
  display: inline-block;
  margin: 10px 0;
  text-decoration: underline;
}

.nav__link {
  display: inline-block;
  position: relative;
  color: black;
  text-decoration: none;
  transition: opacity 0.8s;

  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 1px;
    bottom: -3px;
    left: 0;
    background-color: #000;
    visibility: hidden;
    transform: scaleX(0);
    transition: all 0.3s ease-in-out 0s;
  }

  &.active:before {
    visibility: visible;
    transform: scaleX(1);
  }

  .no-touchevents &:hover {
    opacity: 1;

    &:before {
      visibility: visible;
      transform: scaleX(1);
    }
  }
}
</style>
