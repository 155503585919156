<template>
  <header class="header grid-container">
    <div class="header__logo">
      <router-link class="log-link" :to="{ name: 'Home' }" exact>
        <span class="logo-name">JAN</span>
        <span class="logo-surname">RÖDGER</span>
        <div class="logo-profession">Cinematographer</div>
      </router-link>
    </div>

    <nav class="header__nav">
      <ul class="nav__list">
        <li class="nav__listItem">
          <router-link class="nav__link" :to="{ name: 'Home' }" exact> Work </router-link>
        </li>
        <li class="nav__listItem">
          <a class="nav__link" href="https://www.instagram.com/janroedger/" target="_blank" rel="noopener">Instagram</a>
        </li>
        <!--
        <li class="nav__listItem">
          <router-link class="nav__link" :to="{ name: 'About' }" exact> About </router-link>
        </li>
        -->
      </ul>
    </nav>
  </header>
</template>

<script>
export default {
  name: 'JrHeader',
  props: {},
  methods: {
    goBack() {
      this.$router.push({ name: 'Home' });
    },
  },
};
</script>

<style scoped lang="scss">
.header {
  display: flex;
  font-size: 13px;
  height: 100%;
  justify-content: flex-start;
  padding: 30px 2.5px;
  text-transform: uppercase;
  flex-wrap: wrap;

  @media (min-width: 768px) {
    justify-content: space-between;
  }
}

.header__logo {
  width: 100%;
  margin-bottom: 20px;

  @media (min-width: 768px) {
    width: auto;
    margin-bottom: 0;
  }
}

.header__nav {
  display: flex;
  align-items: center;
}

.nav__list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.nav__listItem {
  display: inline;
  margin-left: 30px;

  &:first-child {
    margin-left: 0;
  }
}

.nav__link {
  position: relative;
  color: black;
  text-decoration: none;
  transition: opacity 0.8s;
}

.nav__link {
  .no-touchevents &:hover {
    opacity: 1;

    &:before {
      visibility: visible;
      transform: scaleX(1);
    }
  }
}

.nav__link:before {
  content: '';
  position: absolute;
  width: 100%;
  height: 1px;
  bottom: -3px;
  left: 0;
  background-color: #000;
  visibility: hidden;
  transform: scaleX(0);
  transition: all 0.3s ease-in-out 0s;
}

.nav__link.active:before {
  visibility: visible;
  transform: scaleX(1);
}

.log-link {
  color: #333;
  text-decoration: none;

  &:visited {
    color: #333;
  }
}

.logo-name,
.logo-surname {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 2em;
}

.logo-surname {
  opacity: 0.6;
  margin-right: 20px;
}

.logo-profession {
  letter-spacing: 2px;
}
</style>
