<template>
  <div class="container" @click="startAnimation"><slot></slot></div>
</template>

<script>
import anime from 'animejs';

export default {
  name: 'JrAnimationTile',

  props: {
    project: {
      type: Object,
      required: true,
    },
  },

  emits: {
    finished: null,
  },

  data() {
    return {
      isAnimating: false,
    };
  },

  methods: {
    startAnimation(event) {
      if (this.isAnimating) return;

      // set animation flag
      this.isAnimating = true;

      const element = event.currentTarget.getElementsByClassName('item')[0];
      // cloned element (wrapper & img)
      const clone = element.cloneNode(true);

      // container
      const contentContainer = document.getElementById('content-container');
      const cloneContainer = document.getElementById('animation-container');

      // get image dimensions
      const srcWidth = element.clientWidth;
      const srcHeight = element.clientHeight;

      // get image position
      const offsetTop = element.getBoundingClientRect().top;
      const offsetLeft = element.offsetLeft;

      // remove old children from clone container
      cloneContainer.innerHTML = '';

      // append clone to clone container
      cloneContainer.appendChild(clone);

      // set image dimensions to clone
      clone.style.width = srcWidth + 'px';
      // clone.style.height = srcHeight + 'px';
      clone.style.top = offsetTop + 'px';
      clone.style.left = offsetLeft + 'px';

      // scroll window to top
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });

      // animation
      const basicTimeline = anime.timeline();
      basicTimeline
        .add({
          targets: cloneContainer,
          opacity: '1',
          duration: 0,
          easing: 'linear',
        })
        // image animation
        .add({
          targets: cloneContainer.firstChild,
          top: contentContainer.offsetTop,
          left: '0',
          minWidth: '100%',
          easing: 'easeInOutQuart', // easeInOutCubic
          complete: () => {
            this.isAnimating = false;
            this.$emit('finished');
          },
        })
        // background opacity animation
        .add(
          {
            targets: contentContainer,
            opacity: ['1', '0'],
            duration: 500,
            easing: 'easeInOutQuart',
          },
          0,
        );
    },
  },
};
</script>

<style scoped lang="scss">
.container {
  height: 100%;
}
</style>
