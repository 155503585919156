import App from './App.vue';
import router from '@/router';
import { createApp } from 'vue';

const isTouchDevice = () => {
  const prefixes = ' -webkit- -moz- -o- -ms- '.split(' ');
  const mq = query => window.matchMedia(query).matches;

  /* istanbul ignore else */
  // eslint-disable-next-line
  if ('ontouchstart' in window || (window.DocumentTouch && document instanceof DocumentTouch)) {
    return true;
  }

  // include the 'heartz' as a way to have a non matching MQ to help terminate the join
  // https://git.io/vznFH
  const query = ['(', prefixes.join('touch-enabled),('), 'heartz', ')'].join('');
  return mq(query);
};

if (!isTouchDevice()) {
  const root = document.getElementsByTagName('html')[0];
  root.classList.add('no-touchevents');
}

const WebFont = require('webfontloader');
WebFont.load({
  google: {
    families: ['Lato'],
  },
});

// prettier-ignore
createApp(App)
  .use(router)
  .mount('#app');
