<template>
  <jr-header></jr-header>
  <main class="main">
    <router-view v-slot="{ Component }">
      <transition :name="transitionName" mode="out-in">
        <component :is="Component" />
      </transition>
    </router-view>
  </main>
  <jr-footer></jr-footer>
</template>

<script>
// compositions
import useProjects from '@/composition/useProducts';

// components
import { JrHeader, JrFooter } from '@/components';

export default {
  name: 'App',

  components: {
    JrHeader,
    JrFooter,
  },

  setup() {
    const { getAllProjects } = useProjects();

    getAllProjects();
  },

  data() {
    return {
      transitionName: 'fade',
    };
  },

  watch: {
    $route(to, from) {
      const toDepth = to.path.split('/').length;
      const fromDepth = from.path.split('/').length;
      this.transitionName = toDepth < fromDepth || toDepth === fromDepth ? 'slide-fade' : '';
    },
  },
};
</script>

<style lang="scss">
@import 'styles/grid';
@import 'styles/transition';

*,
*::before,
*::after {
  box-sizing: border-box;
}

@-ms-viewport {
  width: device-width;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: transparent;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff;
}

#app {
  font-family: 'Lato', monospace;
  -moz-osx-font-smoothing: grayscale;
  backface-visibility: hidden;
  -webkit-font-smoothing: subpixel-antialiased;
  min-height: 100vh;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin-bottom: 0.5rem;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.2;
  color: inherit;
}

h1,
.h1 {
  font-size: 2.5rem;
}

h2,
.h2 {
  font-size: 2rem;
}

h3,
.h3 {
  font-size: 1.75rem;
}

h4,
.h4 {
  font-size: 1.5rem;
}

h5,
.h5 {
  font-size: 1.25rem;
}

h6,
.h6 {
  font-size: 1rem;
}

.main {
  margin-bottom: 60px;
}

.img-fluid {
  display: block;
  width: 100%;
  max-width: 100%;
}
</style>
