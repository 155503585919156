import { createRouter, createWebHistory } from 'vue-router';

import Home from '@/views/Home.vue';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    // meta: { transition: 'slide-fade' },
  },
  {
    path: '/project/:id?',
    name: 'Project',
    props: route => ({ id: route.params.id }),
    component: () => import(/* webpackChunkName: "view_detail" */ '@/views/Project.vue'),
    // meta: { transition: '' },
  },
  {
    path: '/impressum',
    name: 'Impressum',
    component: () => import(/* webpackChunkName: "view_impressum" */ '@/views/Impressum.vue'),
    // meta: { transition: 'slide-fade' },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  },
});

export default router;
