<script>
import Plyr from 'plyr';

import 'plyr/dist/plyr.css';

let player;

export default {
  name: 'JrPlry',

  props: {
    options: {
      type: Object,
      required: false,
      default() {
        return {};
      },
    },
  },

  data() {
    return {
      player: null,
    };
  },

  computed: {
    opts() {
      const options = this.options;

      if (!Object.prototype.hasOwnProperty.call(this.options, 'hideYouTubeDOMError')) {
        options.hideYouTubeDOMError = true;
      }

      return options;
    },
  },

  mounted() {
    player = new Plyr(this.$el, this.opts);
    this.player = player;
  },

  beforeUnmount() {
    try {
      player.destroy();
      player = null;
      this.player = null;
    } catch (e) {
      if (!(this.opts.hideYouTubeDOMError && e.message === 'The YouTube player is not attached to the DOM.')) {
        // eslint-disable-next-line no-console
        console.error(e);
      }
    }
  },

  render() {
    const slots = this.$slots.default;
    return typeof slots === 'function' ? slots()[0] : slots;
  },
};
</script>
